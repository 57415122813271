import React from 'react'

const HeaderSocials = () => {
  return (
    <div className="home__socials">

        <a href="https://instagram.com/atstudyos" className="home__social-link" target="_blank">
            <i className="fa-brands fa-instagram"></i>
        </a>

        <a href="https://www.linkedin.com/in/muhammad-abdullah-tahir-8b6830307/" className="home__social-link" target="_blank">
            <i class="fa-brands fa-linkedin-in"></i>
        </a>

        <a href="https://youtube.com/@GcSpidey" className="home__social-link" target="_blank">
            <i class="fa-brands fa-youtube"></i>
        </a>

        <a href="https://wa.me/923275899477" className="home__social-link" target="_blank">
            <i class="fa-brands fa-whatsapp"></i>
        </a>

        <a href="https://github.com/GcSpideySir" className="home__social-link" target="_blank">
            <i class="fa-brands fa-github"></i>
        </a>

        
    </div>
  )
}

export default HeaderSocials