import React from 'react';
import "./App.css";
import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Services from './components/services/Services';
import Sidebar from './components/sidebar/Sidebar';
import Portfolio from './components/portfolio/Portfolio';
import About from './components/about/About';
import Testimonials from './components/testimonials/Testimonials';
import Blog from './components/blog/Blog';
import Contact from './components/contact/Contact';


const App = () => {
  return (
        <>
            <Sidebar />
            <main className = 'main'>
                <Home/>
                <About/>
                <Services/>
                <Resume/>
                <Portfolio/>
                <Testimonials/>
                <Blog/>
                <Contact/>
            </main>
        
        </>
  )
}

export default App