import React from 'react';
import "./about.css";
import Image from"../../assets/avatar-2.svg"
import AboutBox from './AboutBox';

const About = () => {
  return (
    <section className="about container section" id="about">
      <h2 className="section__title">About Me</h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img" />
        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">I'm a Computer Engineering student at Istanbul Kültür University. 
              I've built projects like a weather-checking app, chatting app, e-commerce app, and currency calculator using 
              no-code tools with algorithms and flowcharts. I have basic knowledge of C and Python, which helps me think logically. 
              Though I don't have job experience yet, I have done few freelance projects I'm focused on learning, improving my skills, and building a strong portfolio 
              to grow as a computer engineer.</p>
            <a href="" className="btn">Download CV</a>
          </div>

          <div className="about__skills grid">
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Wordpress</h3>
                <span className="skills__number">50%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage wordpress"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Android Development</h3>
                <span className="skills__number">70%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage android__dev"></span>
              </div>
            </div>

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">C Fundamentals</h3>
                <span className="skills__number">70%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage c__fund"></span>
              </div>
            </div>  

            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Video Editing</h3>
                <span className="skills__number">40%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage vid__edit"></span>
              </div>
            </div>

              <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Self-Taught Experience</h3>
                <span className="skills__number">60%</span>
              </div>

              <div className="skills__bar">
                <span className="skills__percentage self__exp"></span>
              </div>
              
            
            </div>
          </div>
        </div>
          

      </div>

      <AboutBox/>
    </section>
  )
}

export default About