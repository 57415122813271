import React from 'react';
import "./services.css";
import Image1 from "../../assets/service-1.svg"
import Image2 from "../../assets/service-2.svg"
import Image3 from "../../assets/service-3.svg"

const data = [
  {
    id: 1,
    image: Image1,
    title: "Android App Development",
    description:
      "I create custom Android applications using no-code tools such as Sketchware for clients who need fast, efficient, and cost-effective solutions, tailored              to your needs.",
  },
  {
    id: 2,
    image: Image2,
    title: "Web Development",
    description:
      "I develop custom portfolio, ecommerce, or tool website. Using Wordpress, Woo-commerce and also using traditional Html,Css & JS",
  },
  {
    id: 3,
    image: Image3,
    title: "Video Editing",
    description:
      "I offer professional video editing using DaVinci Resolve to create polished, high-quality videos. For quick, minimal, and engaging edits, I use CapCut.",
  },
];

const Services = () => {
  return <section className="services container section" id='services'>
    <h2 className="section__title">Services</h2>

    <div className="services__container grid">
      {data.map(({id,image,title,description}) => {
        return (
          <div className="services__card" key={id}>
              <img src={image} alt="" className="services__img" />
              <h3 className="services__title">{title}</h3>
              <p className="services__description">{description}</p>
          </div>
        )
      })}
    </div>
  </section>;
}

export default Services